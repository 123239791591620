import React from "react";
import { Link } from "gatsby";

class PostOut extends React.Component {
  render() {
    const posts = this.props.posts;
    //console.log(posts.slice(0,1))
    return (
      <>
        <div style={{ padding: "0% 2%" }}>
          <div id="mn-wrap" style={{ margin: "0em 2.5em" }}>
            {posts.slice(0, 1).map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              return (
                <div
                  key={title}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <div
                    id="main-news"
                    className="box"
                    to={`/${node.fields.slug}`}
                    style={{
                      margin: "2%",
                      width: "90%",
                      maxWidth: "920px",
                      backgroundColor: "#f6f6f6",
                      padding: "20px",
                      border: "1px solid #f3f3f3",
                      borderRadius: "10px"
                    }}
                  >
                    <Link
                      to={`/${node.fields.slug}`}
                      style={{ textAlign: "center" }}
                    >
                      <img
                        loading="lazy"
                        className="blog-img"
                        style={{ borderRadius: "0px 5px", width: "100%" }}
                        alt={node.frontmatter.title}
                        src={node.frontmatter.image}
                      />
                    </Link>
                    <div key={node.fields.slug}>
                      <h3
                        className="is-size-3"
                        style={{ fontWeight: "600", minHeight: "75px" }}
                      >
                        <Link
                          style={{ boxShadow: `none`, minHeight: "75px" }}
                          to={`/${node.fields.slug}`}
                        >
                          {title}
                        </Link>
                        <br />
                        <sub
                          style={{
                            backgroundColor: "#00ff00",
                            color: "white",
                            padding: "0px 10px",
                            borderLeft: "5px solid #111"
                          }}
                        >
                          {node.frontmatter.date}
                        </sub>
                      </h3>
                      <br />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt
                        }}
                      />
                      <br />
                      <Link
                        to={`/${node.fields.slug}`}
                        className="button rexer-button is-size-7"
                      >
                        przeczytaj więcej...
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            id="news"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                maxWidth: "950px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              {posts.slice(1).map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug;
                return (
                  <div
                    key={title}
                    className="box news"
                    to={`/${node.fields.slug}`}
                    style={{
                      margin: "2%",
                      width: "46%",
                      backgroundColor: "#f6f6f6",
                      padding: "20px",
                      border: "1px solid #f3f3f3",
                      minWidth: "225px"
                    }}
                  >
                    <Link to={`/${node.fields.slug}`}>
                      <img
                        loading="lazy"
                        className="blog-img"
                        style={{ borderRadius: "0px 5px", width: "100%" }}
                        alt={node.frontmatter.title}
                        src={node.frontmatter.image}
                      />
                    </Link>
                    <div key={node.fields.slug}>
                      <h3
                        className="is-size-4"
                        style={{ fontWeight: "600", minHeight: "75px" }}
                      >
                        <Link
                          style={{ boxShadow: `none` }}
                          to={`/${node.fields.slug}`}
                        >
                          {title}
                        </Link>
                        <br />
                        <sub
                          style={{
                            backgroundColor: "#00ff00",
                            color: "white",
                            padding: "0px 10px",
                            borderLeft: "5px solid #111"
                          }}
                        >
                          {node.frontmatter.date}
                        </sub>
                      </h3>
                      <br />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt
                        }}
                      />
                      <br />
                      <Link
                        to={`/${node.fields.slug}`}
                        className="button rexer-button is-size-7"
                      >
                        przeczytaj więcej...
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PostOut;
