import React from "react";
import { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo.js";
import PostOut from "../components/postOut";
import { Link, graphql } from "gatsby";
import { gsap } from "gsap";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link
        to={`/aktualnosci/${props.url}#main`}
        className="button standard-button is-size-4"
      >
        {props.text}
      </Link>
    );
  } else {
    return (
      <span disabled className="button standard-button is-size-4">
        {props.text}
      </span>
    );
  }
};

const BlogPage = props => {
  const {
    pageContext: { first, group, index, last }
  } = props;
  const previousUrl = index - 1 === 1 ? "" : (index - 1).toString();
  const nextUrl = (index + 1).toString();

  useEffect(() => {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 4 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 0, duration: 4, delay: 1 });
  }, []);

  return (
    <>
      <Layout>
        <SEO title="Aktualności" />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>Aktualności </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    BĄDŹ Z NAMI <br /> NA BIEŻĄCO
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  W tym miejscu znajdziecie Państwo{" "}
                  <strong>informacje, ogłoszenia oraz artykuły</strong>{" "}
                  dotyczące naszej działalności. Publikujemy artykuły związane
                  nie tylko z wydarzeniami z naszej firmy, ale również na temat
                  szeroko pojętej inżynierii środowiska.
                </p>
                <a href="#main" className="button rexer-button">
                  Przeczytaj
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "0px",
                    marginBottom: "-55px",
                    marginLeft: "-20px",
                    maxWidth: "550px",
                    opacity: "1"
                  }}
                  src="/img/tree-hand.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-175px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/pcat.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  <b>Zapraszamy do lektury</b> naszych firmowych publikacji.
                  Zapisz się na newletter!
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#newsletter">
                  Subskrybuj
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%",
            position: "relative",
            zIndex: "2"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px"
            }}
          >
            <strong style={{ textTransform: "uppercase" }}>
              ARTYKUŁY, OGŁOSZENIA ORAZ INFORMACJE
            </strong>
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            Piszemy o tym <b>co dla nas ważne</b>. Zwięźle i na temat. Poniżej
            zbiór naszych publikacji, na tematy związane z{" "}
            <b>naszą pracą i zainteresowaniami</b>! Dowiedz się o wydarzeniach
            oraz nowościach jako pierwszy czytając nasze aktualności.
          </p>
        </div>

        <div style={{ padding: "3em", marginTop: "-1em" }}>
          <PostOut posts={group} />
          <div className="buttons is-centered" style={{ marginTop: "25px" }}>
            <PaginationLink test={first} url={previousUrl} text="<" />
            <PaginationLink test={last} url={nextUrl} text=">" />
          </div>
        </div>

        {/*<div className="columns">
      <div className="is-bold column" style={{textAlign:'center', padding:'0% 5% 2.5% 5%'}}>
      <p className="is-size-3">Poznaj naszą ofertę</p>
      <p>Zobacz jak możemy pomóc Twojej firmie.</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/oferta/">
        Oferta
      </Link>
      </div>

      <div className="is-bold column" style={{textAlign:'center', padding:'0% 5% 2.5% 5%'}}>
      <p className="is-size-3">Zobacz realizacje</p>
      <p>Sprawdź jakie efekty przyniosła nasza praca.</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/realizacje/">
        Realizacje
      </Link>
      </div>
      </div>
      <br /><br />

      <div style={{textAlign:'center',paddingBottom:'5%'}}>

      <p>Wróć do przeglądania</p>
      <br />
      <Link className="button rexer-button is-size-5" to="/">
      Home
      </Link>
      </div>*/}

        <Newsletter />
        <CTA />
      </Layout>
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(pruneLength: 160)
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            description
            language
            templateKey
            image
          }
        }
      }
    }
  }
`;
